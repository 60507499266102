const EN = {
  webName: ' Customer Service',
  welcome: "Hello, welcome to online customer service consultation, please click on the question you need to consult and we will reply to you in time, thank you!<br>",
  Home: {
    t1: "contact service",
    t2: "I like to make wheels in a neat and orderly manner",
    t3: "Send (s)",
    t4: "Please enter your question",
    t5: "  serve you",
    t6: "user",
    t7: "The sent content cannot be empty"
  },
  customerServiceLogin: {
    t1: "Customer service login",
    t2: "Please enter your account ID",
    t3: "Please enter the password",
    t4: "empty",
    t5: "log on",
  },
  customerService: {
    t1: "Nickname: ",
    t2: "Maximum simultaneous reception: ",
    t3: "Reception frequency: ",
    t4: "on line",
    t5: "offline",
    t6: "You have a new user accessing",
    t7: "Online conversation",
    t8: "Kick out session",
    t9: "Offline Session",
    t10: "delete",
    t11: "Waiting for user to connect",
    t12: "No session content",
    t13: "After the customer connects, select the customer from the left customer list to start the conversation",
    t14: "friends sharing same hobby",
    t15: "Click on the list on the right to start your conversation!",
    t16: "Send (s)",
    t17: "Log out of login",
    t18: "Joined the conversation",
    t19: "Joined the session again",
    t20: "Don't joke, Taoist friends. Times have changed, and your ancient browsing tools are too outdated!",
    t21: "The input content cannot be empty",
    t22: "The other party is offline and unable to receive messages in real-time, but they can still view this message through historical messages",
    t23: "There's no more"
  },
  customerChat: {
    t1: "back",
    t2: "Send (s)",
    t3: "Please enter the session content",
    t4: "The current customer service is offline",
    t5: "Don't joke, Taoist friends. Times have changed, and your ancient browsing tools are too outdated!",
    t6: "You have closed the session",
    t7: "The sent content cannot be empty",
    t8: "You withdrew a message",
    t9: "The other party withdrew a message"
  },
  Comment: {
    t1: "Please leave a message",
    t2: "Message Record",
    t3: "submit to",
    t4: "To be replied to",
    t5: "Λ Fold up",
    t6: "Detail",
    t7: "Customer service: ",
    t8: "Hello, we are sorry that we are currently unable to provide you with services. If you need assistance, please leave a message and we will contact and resolve your issue as soon as possible",
    t9: "Acquisition failed",
    t10: "Submitted successfully",
    t11: "Submission failed",
  },
  CommentReply: {
    t1: "replied",
    t2: "cancellation",
    t3: "reply",
    t4: "customer service: ",
    t5: "submit",
    t6: "Subsequent extension content",
    t7: "Acquisition failed",
    t8: "Reply successful",
    t9: "Reply failed",
    t10: "Message reply",
    t11: "Subsequent extension content",
    t12: "There's no more"
  },
  HomeAiChat: {
    t1: "The customer service is currently busy, and you are currently ranked in the",
    t2: "",
    t3: "cancellation",
    t4: "Please cancel the queue first",
  },
  MessageWindow: {
    t1: "Load more",
    t2: "You have connected to customer service, let's start consulting! (Long press on the message to recall it)",
    t3: "withdraw",
    t4: "The user has connected, start replying! (Click on the message to recall the message)",
    t5: "The user is offline, but you can still have a conversation and the other party can view it through historical messages"
  },
  MyInput: {
    t1: "Modify nickname",
    t2: "Maximum number of receptionists"
  },
  SendImage: {
    t1: "Please select an image file!",
    t2: "ploading images cannot exceed 10M!"
  },
  ServiceRightPage: {
    t1: "User ID: ",
    t2: "User name: ",
    t3: "Temporary room number: ",
    t4: "Temporary service id: ",
    t5: "User status: ",
    t6: "Customer Information",
    t7: "Quick reply",
    t8: 'Author',
    t9: "User IP: ",
    t10: "User Region: ",
    t11: "User Equipment: ",
  }
}
export default EN